@import "../../styles/utils.scss";

.float-label > .ant-select .ant-select-selector {
  border: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  padding: 0px;
}

.float-label > * {
  box-shadow: none !important;
  border: none;
  outline: none;
  padding: 0;
}

.float-label {
  position: relative;
  padding: em(6.5) em(0);
  border-bottom: em(2) solid $header-text-color;
  transition: all 0.5s;
  margin-bottom: em(5);
  margin-top: em(30);
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
  .ant-select-selection-item {
    height: fit-content;
  }
}

.label {
  z-index: 1;
  position: relative;
  transition-duration: 400ms;
  left: em(5);
  transform: translate(0px, 2px);
  top: em(25);
  bottom: em(8);
  color: $primary-color;
}

.as-placeholder {
  color: $primary-color;
  width: 100%;
  word-break: break-word;
}

.as-label {
  top: em(2);
  color: $primary-color;
  width: 100%;
  word-break: break-word;
}

.border-label {
  border-bottom: em(2) solid $primary-color;
}

.float-label > .ant-select-selector {
  height: em(22);
}

.float-label:has(.ant-select-selector) {
  padding-bottom: em(5);

}

.float-label > .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: em(22);
  margin-top: -2px;
}