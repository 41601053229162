@import "./utils.scss";
@import "../../node_modules/antd/dist/antd.css";

body {
  font-family: Poppins Regular;
  font-size: em(16);
  margin: 0;
  padding: 0;
  overflow: hidden;
}

main {
  background-color: $app-background-color;
  color: $text-primary;
  overflow-x: hidden;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
ul,
ol,
p {
  margin: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: em(36);
}

h2,
.h2 {
  font-size: em(32);
}

h3,
.h3 {
  font-size: em(22);
}

h4,
.h4 {
  font-size: em(18);
}

h5,
.h5 {
  font-size: em(16);
}

h6,
.h6 {
  font-size: em(13);
}

@include generateUtilClass("hr-fs", "font-size");

@include generateUtilClass("hr-w", "width");

@include generateUtilClass("hr-h", "height");

@include generateUtilClass("hr-m", "margin");

@include generateUtilClass("hr-mr", "margin-right");

@include generateUtilClass("hr-mt", "margin-top");

@include generateUtilClass("hr-mt", "margin-top");

@include generateUtilClass("hr-mb", "margin-block-end", true);

@include generateUtilClass("hr-ml", "margin-left");

@include generateUtilClass("hr-p", "padding");

@include generateUtilClass("hr-pl", "padding-left");

@include generateUtilClass("hr-pr", "padding-right");

@include generateUtilClass("hr-pb", "padding-bottom");

.hr-p-0 {
  padding: 0;
}
.w-100 {
  width: 100%;
}
.w-95 {
  width: 95%;
}
.w-90 {
  width: 90% !important;
}
.w-85 {
  width: 85%;
}
.w-75 {
  width: 75%;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.w-39 {
  width: 39% !important;
}
.w-38 {
  width: 38% !important;
}
.min-w-30 {
  min-width: em(30);
}
.h-100 {
  height: 100%;
}

.min-h-230 {
  min-height: 230px;
}
.max-h-460 {
  max-height: 460px;
}
.ln-h-0 {
  line-height: 0;
}
.ln-h-n {
  line-height: normal;
}

// FONT WEIGHTS

.poppins-light {
  font-family: Poppins Light;
}
.poppins-medium {
  font-family: Poppins Medium;
}
.poppins-semibold {
  font-family: Poppins SemiBold;
}
.poppins-bold {
  font-family: Poppins Bold;
}

// TEXT SIZE

.font-20 {
  font-size: em(20);
}
.font-18 {
  font-size: em(18);
}
.font-16 {
  font-size: em(16);
}
.font-15 {
  font-size: em(15);
}
.font-14 {
  font-size: em(14);
}
.font-12 {
  font-size: em(12);
}
.font-10 {
  font-size: em(10);
}
.font-8 {
  font-size: em(8);
}
.font-6 {
  font-size: em(6);
}

// ICON SIZE

.icon-30 {
  width: em(30);
  height: em(30);
}

.icon-26 {
  width: em(26);
  height: em(26);
}

.icon-20 {
  width: em(20);
  height: em(20);
}

.icon-18 {
  width: em(18);
  height: em(18);
}
.icon-12 {
  width: em(12);
  height: em(12);
}

// COLORS CLASSES

.hr-text-dark-grey {
  color: $text-dark-grey;
}
.hr-text-light-grey {
  color: $text-light-gray;
}
.hr-text-light-grey-2 {
  color: $placeholder-secondary-color;
}
.text-primary {
  color: $text-primary;
}
.text-secondary {
  color: $text-secondary;
}
.text-light-secondary {
  color: $text-light-secondary;
}
.text-secondary-gray {
  color: $text-secondary-gray;
}
.text-blue {
  color: $text-btn;
}
.text-red {
  color: $text-red;
}
.error-text {
  color: $field-error;
  font-size: em(14);
}
.field-error-test {
  color: $field-error;
}
.link-text {
  color: $link;
}
.recording-link-red {
  color: $recording-link1;
}
.table-head-black {
  color: black;
}
// TEXT DECORATION

.italic {
  font-style: italic;
}

// CUSTOM STYLING

.p-0 {
  padding: 0 !important;
}
.cursor {
  cursor: pointer;
}
.main-container {
  padding: em(20) em(30);
}

.secondary-container {
  padding: em(30);
}

.d-block {
  display: block;
}
.d-none {
  display: none;
}
.display-flex {
  display: flex;
  align-items: center;
}
.d-flex {
  display: flex;
}
.d-flex-row {
  display: flex;
  flex-direction: row;
}
.d-flex-column {
  display: flex;
  flex-direction: column;
}
.self-center {
  align-self: center;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-start {
  align-items: start;
}
.align-inherit {
  align-items: inherit;
}
.justify-center {
  justify-content: center;
}
.justify-sb {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.word-break-all {
  word-break: break-all;
}
.pointer {
  cursor: pointer;
}
.float-right {
  float: right;
}
.hidden {
  display: none;
}

.op-4 {
  opacity: 0.4;
}
.op-6 {
  opacity: 0.6;
}
.z-in-1 {
  z-index: 1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-clip: padding-box;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-clip: padding-box;
  background-color: $text-secondary;
}

.borderlessInput {
  color: $text-primary;
  border: none;
  border-bottom: 1px solid $text-secondary !important;
  border-radius: 0;
  &:focus {
    box-shadow: none;
    border: none;
    outline: 0;
  }
  &:hover {
    outline: 0;
  }
  &::placeholder {
    opacity: 0.5;
    color: $placeholder-secondary-color !important;

    font-size: em(18);
  }
}
.div-container {
  border-radius: em(4);
  padding: em(8) em(15);
  overflow: hidden;
  background: white;
  box-shadow: 0px 0px 5px $text-secondary;
}
.custom-vertical-divider {
  position: relative;
  top: 0;
  display: inline-block;
  height: 100%;
  margin: 0;
  vertical-align: middle;
  border-radius: em(10);
  border-left: em(1) solid $divider-color;
}
.custom-horizontal-divider {
  border-top: em(1) solid $divider-color2 !important;
}
.gray-counter {
  background-color: $light-gray;
  color: $dark-gray;
  min-width: em(20);
  min-height: em(20);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 4px;
    margin-right: 4px;
    font-size: em(10);
  }
}
.blue-counter {
  background-color: $primary-color;
  color: $white;
  min-width: em(20);
  min-height: em(20);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 4px;
    margin-right: 4px;
    font-size: em(10);
  }
}
.pdf-container {
  padding: em(10) em(20) em(20);

  canvas {
    width: 100% !important;
    height: max-content !important;
    max-width: 856px !important;
    margin: auto;
  }
}
.attachment-text {
  cursor: pointer;
  color: $text-primary;
  font-family: Poppins Medium;
  font-size: em(12);
}
.disable-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gap-6 {
  gap: em(6);
}

.thankyou-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-50vh {
  height: 50vh;
}

textarea {
  resize: none;
}

// OVERBIDDEN STYLING

.ant-menu-inline {
  border: 0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.ant-layout {
  position: relative;
  background: none;

  .layout-content {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .full-scroll & {
      overflow-x: inherit;
    }
  }
  .app-layout {
    height: 100vh;
  }
}
.customized-tab {
  letter-spacing: em(0.5);
  margin-left: em(50);

  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab {
    padding-top: 0;
    padding-bottom: em(5);
    color: $text-secondary;
    font-size: em(16);
  }
  .ant-tabs-tab:hover {
    color: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $text-primary;
    font-weight: 500;
  }
  .ant-tabs-nav::before {
    border: 0;
  }
  .ant-tabs-ink-bar {
    height: em(3) !important;
    background: $primary-color;
    border-radius: em(10);
  }
}
.ant-divider {
  border-top: 2px solid $secondary-color;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}
.collapsable-card {
  .ant-card-body {
    padding: em(12);
    .collapse-container {
      background-color: $white;
      .ant-collapse-item {
        background: transparent;
        border-radius: em(4);
        border: none;
        .ant-collapse-header .ant-collapse-header-text {
          font-size: em(16);
        }
      }
    }
  }
}

.custom-typography {
  &:first-child {
    margin-bottom: em(3);
  }
}

.ant-checkbox-inner {
  border: 1px solid $check-box-border;
  border-radius: 2px;

  &:hover {
    border-color: $ocean-green;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $ocean-green;
  border-color: $ocean-green;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $ocean-green;
}

.ant-radio-inner {
  border: 1px solid $check-box-border;

  &:hover {
    border-color: $ocean-green !important;
  }
}
.ant-radio:hover .ant-radio-inner {
  border-color: $ocean-green;
}
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $ocean-green;
  border-width: em(2);
  box-shadow: none;
}

.custom-picker {
  border-radius: em(4);
  height: em(40);
  background-color: $secondary-color;
  .ant-picker-input > input {
    &::placeholder {
      opacity: 50%;
      color: $placeholder-text-color !important;

      font-size: em(14);
    }
  }
  &:focus {
    border-color: $secondary-color;
    box-shadow: 0 0 0 2px $secondary-color;
    border-right-width: 1px;
    outline: 0;
  }
  &:hover {
    border-color: $text-secondary;
    box-shadow: 0 0 0 2px #e7e8e9;
    border-right-width: 1px;
    outline: 0;
  }
}

.ant-picker-focused {
  border-color: $secondary-color;
  box-shadow: 0 0 0 2px $secondary-color;
  border-width: 1px solid;
  outline: 0;
}

.fc {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  th {
    border-color: $text-secondary;
    border-right: none;
    border-left: none;
    &:first-child {
      // border-style: none;
    }
  }
  .fc-timegrid-slot {
    height: em(40);
    cursor: pointer;
  }
  .fc-timegrid-slot-minor {
    border-style: none;
  }
  .fc-timegrid-col.fc-day-today {
    background-color: $selected-menu;
  }

  .fc-timeGridWeek-view {
    th {
      &:first-child {
        border-style: none;
      }
    }

    td {
      &:first-child {
        border-top: none;
        border-bottom: none;
      }
    }
  }
}

.fc-theme-standard {
  .fc-scrollgrid {
    border-style: none;
  }
  td {
    &:first-child {
      // border-top: none;
      // border-bottom: none;
    }

    .fc-daygrid-event {
      background-color: $event-bg-color;
      overflow: hidden;
      color: $white;
    }
  }

  .fc-day-sun {
    .fc-daygrid-day-frame {
      border-left: 1px solid $text-secondary !important;
    }
  }
}

.fc-timegrid-col {
  &:first-child > div {
    border-style: none;
  }
  > div {
    border-bottom: 1px solid $text-secondary;
  }
}

.ant-picker-calendar .ant-picker-panel {
  border-width: 0;
}

.ant-picker-content {
  table-layout: auto;
}

.ant-picker-panel,
.ant-picker-date-panel {
  width: 100%;
}

.custom-calendar {
  .ant-picker-cell-selected {
    background-color: $text-primary;
    color: $white;
    border-radius: em(5);
  }
  .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-selected {
    td {
      height: 0 !important;
    }
  }
  table thead tr > th {
    visibility: hidden;
    font-size: 0;
  }
  table thead tr > th::after {
    font-family: Poppins Medium;
    color: $dark-gray;
    visibility: visible;
    font-size: 12px;
  }
  table thead tr > th:nth-child(1)::after {
    content: "S";
  }
  table thead tr > th:nth-child(2)::after {
    content: "M";
  }
  table thead tr > th:nth-child(3)::after {
    content: "T";
  }
  table thead tr > th:nth-child(4)::after {
    content: "W";
  }
  table thead tr > th:nth-child(5)::after {
    content: "T";
  }
  table thead tr > th:nth-child(6)::after {
    content: "F";
  }
  table thead tr > th:nth-child(7)::after {
    content: "S";
  }
}
.custom-badge {
  .ant-badge-status-processing {
    cursor: pointer;
    position: absolute;
    background-color: $primary-color;
    top: em(12);
    right: em(10);
    width: em(14);
    height: em(14);
    border: 1px solid $white;

    &::after {
      left: em(2);
      width: em(10);
      height: em(10);
      border: 1px solid $white;
    }
  }
}
.custom-toggle {
  .ant-switch {
    background-color: $toggle-bg;
  }
  .ant-switch-handle::before {
    background-color: $white;
    box-shadow: 0;
  }
  .ant-switch-handle::after {
    background-color: $white;
    box-shadow: 0;
  }
}
.ant-switch:focus {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: $primary-color;
  &:focus {
    box-shadow: none;
  }
}
.warning-icon {
  font-size: em(50);
  color: $warning-yellow;
}
.disabled-select {
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
  }
  .as-placeholder {
    color: $text-secondary;
  }
}
.ant-select-item-empty {
  text-align: center;
}

.no-pointer {
  cursor: not-allowed;
  opacity: 0.3;
}
// .disabled-link{
//   cursor: not-allowed;
//   pointer-events: none;
// }
@media (max-width: 768px) {
  .text-end {
    text-align: start;
  }
  .ant-checkbox-wrapper {
    display: flex;
    margin-top: em(10);
  }
}
.pad-left {
  padding-left: 5px !important;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
