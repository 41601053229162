@import "../../styles/utils.scss";

.hr-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: em(40);
    border-radius: em(4);
    position: relative;
    background-color: $white;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
    .ant-select-selection-placeholder {
      opacity: 50%;
      font-size: em(14);
    }
    &:hover {
      outline: 0;
    }
  }
  &:focus {
    box-shadow: 0 0 0 2px $secondary-color !important;
    outline: 0;
  }
}

.hr-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $white;
    .ant-select-item-option-content {
      color: $text-primary;
    }
  }
}

.gray-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $secondary-color !important;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: em(1) solid $border-secondary;
  }
  .ant-select-selector {
    background-color: $secondary-color !important;
    .ant-select-selection-placeholder {
      color: $placeholder-text-color !important;
    }
    &:hover {
      border-color: $text-secondary !important;
      box-shadow: 0 0 0 2px $secondary-color;
      border-right-width: 1px;
    }
    .ant-select-selection-item {
      color: $text-primary !important;
    }
  }
  &:focus {
    border-color: $secondary-color;
    border-right-width: em(1);
  }
  svg {
    fill: $placeholder-text-color;
  }
}

.blue-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  .ant-select-selector {
    background-color: $primary-color !important;
    .ant-select-selection-placeholder {
      color: $white !important;
    }
    &:hover {
      border-color: $white;
    }
    .ant-select-selection-item {
      color: $white !important;
    }
  }
  &:focus {
    border: 0;
  }
  svg {
    fill: $white;
  }
}

.white-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  .ant-select-selector {
    background-color: $white !important;
    .ant-select-selection-placeholder {
      color: $text-secondary !important;
    }
    &:hover {
      border-color: $white;
    }
    .ant-select-selection-item {
      color: $text-secondary !important;
    }
  }
  &:focus {
    border: 0;
  }
  svg {
    fill: $text-secondary;
  }
}

.green-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $text-green-primary;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $text-green-primary;
    border-radius: em(20);
    padding: em(2) em(20);
  }
  .ant-select-selector {
    background-color: $light-green !important;
    .ant-select-selection-placeholder {
      color: $text-secondary !important;
    }
    &:hover {
      border-color: $text-green-primary !important;
    }
    .ant-select-selection-item {
      color: $text-green-primary !important;
    }
  }
  &:focus {
    border: 0;
  }
  svg {
    fill: $text-green-primary;
  }
}

.red-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $help-red;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid $help-red;
    border-radius: em(20);
    padding: em(2) em(20);
  }
  .ant-select-selector {
    background-color: $light-red !important;
    .ant-select-selection-placeholder {
      color: $text-secondary !important;
    }
    &:hover {
      border-color: $help-red !important;
    }
    .ant-select-selection-item {
      color: $help-red !important;
    }
  }
  &:focus {
    border: 0;
  }
  svg {
    fill: $help-red;
  }
}

.lightGray-select {
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  &:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  .ant-select-selector {
    background-color: $app-background-color !important;
    .ant-select-selection-placeholder {
      color: $text-secondary !important;
      font-size: em(12);
    }
    &:hover {
      border-color: $white;
    }
    .ant-select-selection-item {
      color: $text-secondary !important;
    }
  }
  &:focus {
    border: 0;
  }
  svg {
    fill: $text-secondary;
  }
}
