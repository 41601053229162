@import "../../styles/utils.scss";

.topbar-container {
  padding: em(15) em(30);
  width: 100%;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 1px 10px $drop-shadow;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.detail-topbar-container {
  width: 100%;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 1px 10px $drop-shadow;
  display: flex;
  justify-content: flex-start;
  padding: em(15) em(50);
}

@media screen and (max-width: 500px) {
  .detail-topbar-container {
    padding: em(15) em(32);
    .language-dropdown {
      display: none;
    }
  }
}

.logout-modal {
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-close {
      padding: 0 !important;
    }
  }

  .confirm-button{
    background-color:$primary-color !important
    ;
  }
 
}

.img-container {
  width: 100px;
  height: 50px;
  object-fit: contain;
}

.external-form-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .detail-topbar-container {
    padding: em(10) em(12);
  }
}
