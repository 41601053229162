@font-face {
  font-family: "Poppins Thin";
  src: url("./Poppins-Thin.ttf?#iefix") format("truetype"),
    url("./Poppins-Thin.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins ExtraLight";
  src: url("./Poppins-ExtraLight.ttf?#iefix") format("truetype"),
    url("./Poppins-ExtraLight.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins Light";
  src: url("./Poppins-Light.ttf?#iefix") format("truetype"),
    url("./Poppins-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("./Poppins-Regular.ttf?#iefix") format("truetype"),
    url("./Poppins-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./Poppins-Medium.ttf?#iefix") format("truetype"),
    url("./Poppins-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./Poppins-SemiBold.ttf?#iefix") format("truetype"),
    url("./Poppins-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("./Poppins-Bold.ttf?#iefix") format("truetype"),
    url("./Poppins-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins ExtraBold";
  src: url("./Poppins-ExtraBold.ttf?#iefix") format("truetype"),
    url("./Poppins-ExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins Black";
  src: url("./Poppins-Black.ttf?#iefix") format("truetype"),
    url("./Poppins-Black.ttf") format("ttf");
}