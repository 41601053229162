@import "../../styles/utils.scss";

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ).ant-select-open
  .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ).ant-select-focused
  .ant-select-selector {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0);
}