@import "../../../styles/utils.scss";
@import "../../../assets/fonts/styles.scss";

.card-container {
  height: 100%;
  padding: em(40);
  border-radius: em(18);

  &:first-child {
    // height: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .heading-input {
      color: $text-primary;
      font-size: em(28);
      font-family: Poppins Bold;
    }
    .btn-auth {
      width: 100%;
      padding: em(13);
      height: auto;
      border-radius: em(8);
      margin-top: em(12);
      margin-bottom: em(28);
      font-size: em(20);
      background-color: $primary-color;
    }
    .back-to {
      text-align: center;
      font-family: Poppins Bold;
      font-size: em(18);
      cursor: pointer;
    }
    // Login
    .login-container {
      .password {
        margin-bottom: em(8);
      }
      .remember {
        display: flex;
        align-items: center;
        font-family: Poppins SemiBold;
        p {
          cursor: pointer;
        }
      }
      .para {
        text-align: center;
      }
      .primary {
        color: $primary-color;
        font-family: Poppins SemiBold;
        cursor: pointer;
      }
    }

    .email-container {
      .password-reset {
        color: #707070;
        .email {
          color: $text-primary;
          font-family: Poppins SemiBold;
          font-size: em(19);
        }
      }
      .receive-email {
        color: #707070;
        // text-align: center;
        margin-top: em(10);
        // margin-bottom: em(30);
        .resend {
          color: $primary-color;
          font-family: Poppins SemiBold;
        }
      }
    }

    .successful-container {
      .img-success {
        display: flex;
        justify-content: center;
      }
      .para-success {
        margin-bottom: em(55);
      }
    }

    .signup-container {
      .para {
        text-align: center;
        span {
          color: $primary-color;
          cursor: pointer;
          font-family: Poppins SemiBold;
        }
      }
    }

    .account-container {
      .img-success {
        display: flex;
        justify-content: center;
      }
      .para-success {
        font-size: em(19);
      }
    }
  }
  .hr-mb-0{
    margin-bottom: 0 !important;
  }
  .hr-mt-28{
    margin-top: em(28) !important;
  }
}
