@import "../../../../styles/utils.scss";
.external-form-container {
  background-color: $secondary-body-color;
  height: 100vh;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  .main-content {
    width: 100%;
    max-height: calc(100vh - em(77));
    overflow: auto;
    padding: em(30) em(120);
    .full-height {
      min-height: calc(100vh - (em(213)));
      button {
        background-color: $primary-color;
        padding: em(12) em(48);
        height: auto;
        border: 1px solid $primary-color;
      }
    }
    .upload-resume-list {
      display: flex;
      align-items: center;
      border: 1px solid $placeholder-text-color;
      border-radius: 4px;
      width: fit-content;
      padding: em(6) em(8) em(6) em(8);
    }
  }
  .external-form-label {
    font-size: em(14);
    color: $primary-color;
  }

  .signature-canvas {
    width: 100%;
    height: 300px;
    outline: 1px solid;
  }
  .signature-canvas:hover {
    cursor: url("https://hrvirtuoso-prod.s3.amazonaws.com/Media/User/79943b6b-e323-410d-a168-5c6676fff821/penc73b24b3-3418-42da-a813-16ffd6817a06.png?AWSAccessKeyId=AKIAT4ZFS5IKEANQ27AS&Expires=2024552231&Signature=WBq09hlOhsut2hmsJB8lzIcgTb4%3D") -55
        60,
      auto;
  }

  .external-form-item {
    margin-bottom: em(0);
  }

  .checkbox-group,
  .radio-group {
    margin-top: em(10);
  }
}
.company-logo {
  max-height: em(56);
  max-width: em(148);
}

.responsive-group {
  display: flex;
  flex-direction: column;
}

._mt-30 {
  margin-top: em(-30);
}
@media (max-width: 768px) {
  .external-form-container {
    .main-content {
      padding: em(4) em(0);
    }
    .external-from-label {
      font-size: em(14) !important;
      width: 100% !important;
      font-weight: 600;
    }
    .location-address {
      font-size: 16px;
      margin-left: em(30);
    }
    .responsive-title {
      margin-left: em(10);
    }
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
    }
    .checkbox-group,
    .radio-group {
      margin-top: em(20);
    }
  }
  .icon-location-container {
    padding: 0 25px;
  }
  .responive-colorDiv {
    padding: em(5) em(22) em(22) em(22);
  }
  .responsive-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: em(5) em(0);
  }
}
