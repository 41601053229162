@import "../../styles/utils.scss";

.custom-modal {
  .ant-modal-content {
    padding: 20px;
    .ant-modal-close {
      padding: 20px;
    }
  }
  .ant-modal-header {
    border-bottom: none;
    .ant-modal-title {
      font-family: poppins bold;
    }
    span {
      padding: 25px;
    }
  }
}
