@import "../../styles/utils.scss";

.dropdown-menu {
  font-size: 16px;
  // box-shadow: 0 em(3) em(6) em(-4) $notification-shade-1, 0 em(6) em(16) 0 $notification-shade-1,
  //   0 em(9) em(28) em(8) $notification-shade-1;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: $white;
  border-radius: 0;
  padding: em(20);
  min-height: em(293);
  max-height: em(351);
  width: em(351);
  overflow-y: auto;

  scrollbar-width: auto;
  scrollbar-color: $text-secondary;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: em(12);
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $text-secondary;
    border: em(8) solid $white;
    border-left: 0;
  }

  &-item {
    cursor: pointer;
    white-space: normal;
    color: $text-primary;

    p:nth-child(2) {
      color: $notification-time;
    }
    &:hover {
      background-color: $secondary-color;
    }
  }
}
