@import "../../styles/utils.scss";

.container {
  border-radius: 4px;
  overflow: hidden;
  font-size: 16px;
  background: white;
  box-shadow: 0px 0px 5px $text-secondary;
}
.color-ocean-green {
  background-color: $ocean-green;
}
.color-orange {
  background-color: $orange;
}
.color-gold {
  background-color: $dull-golden;
}
.color-blue {
  background-color: $primary-color;
}
.color-gray {
  background-color: $dark-gray;
}
.color-yellow {
  background-color: $yellow;
}
.color-violet {
  background-color: $violet;
}
.color-green {
  background-color: $dull-green;
}
.colordiv-width{
  width: 100% !important;
}

@media (max-width: 768px) {
 .container{
  margin-left:em(20);
  margin-right:em(20);
  margin-bottom:em(20);

 }
}