@import "../../../../styles/utils.scss";

.external-listing-container {
  background-color: $secondary-body-color;
  height: 100vh;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: calc(100vh - em(77));
    overflow: auto;
    padding: em(30) em(30);

    .tag-style {
      color: $text-green-primary;
      font-family: Poppins Regular;
      font-size: em(14);
      border-radius: em(30);
      padding: em(8) em(25);
    }
    .green {
      border-color: $text-green-primary;
      background-color: $light-green;
      color: $text-green-primary;
    }
    .red {
      border-color: $help-red;
      background-color: $light-red;
      color: $help-red;
    }

    .jobs-container,
    .locations-container {
      width: 100%;
    }

    .jobs-scroll-view {
      max-height: em(390);
      overflow-y: scroll;
    }

    .default-location-loader {
      .ant-skeleton-content {
        display: flex;
        align-items: center;
        .ant-skeleton-title {
          width: 100% !important;
        }
      }
    }
  }
}
