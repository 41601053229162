@import "../styles/utils.scss";

.wrapper-container {
  background-image: url(../assets/images/tiny-hrv-background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  height: 100vh;
  overflow: auto;
  // display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;

  .right-section {
    height: 100%;
  }
  .row-container {
    padding: 12vh 8vw;
    height: 100%;
    margin: auto 0;

    align-items: flex-end;
    margin: 0 !important;

    .stream {
      color: white;
      font-size: em(30);
      margin-top: em(25);
      margin-bottom: em(8);
      span {
        font-family: Poppins SemiBold;
      }
    }
    .para-wrap {
      color: white;
    }
    .h-100 {
      height: 100%;
    }
  }

  @media screen and (max-width: $tablet) {
    .row-container {
      padding: 6%;
      height: auto;
    }
    .right-section {
      height: 50%;
      margin: 15% 0;
    }
  }
}
