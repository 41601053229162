@import "../../styles/utils.scss";
.hr-btn {
  border-radius: em(4);
  &-primary {
    background-color: $primary-color !important;
    padding: em(8) em(24);
    height: auto;
    border: 1px solid $primary-color;
    span {
      color: $secondary-body-color;
    }

    &:hover {
      background-color: transparent;
      border: 1px solid $primary-color;
    }
  }

  &-dashed {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color;
    border-style: dashed;

    &:hover {
      background-color: transparent;
    }
  }

  &-purple {
    display: flex;
    align-items: center;
    background-color: $violet !important;
    padding: 0 em(15);
    border: 0;
  }

  &-gray {
    display: flex;
    align-items: center;
    background-color: $dark-gray !important;
    padding: 0 em(15);
    border: 0;
  }

  &-blue {
    // display: flex;
    align-items: center;
    background-color: $primary-color !important;
    padding: 0 em(15);
    border: 0;
  }

  &-green {
    display: flex;
    align-items: center;
    background-color: $green !important;
    padding: 0 em(15);
    border: 0;
  }
  &-red {
    display: flex;
    align-items: center;
    background-color: $text-red !important;
    padding: 0 em(15);
    border: 0;
  }
  &-white {
    background-color: $white !important;
    color: $black;
    padding: 0 em(15);
    border: 1px solid $placeholder-text-color;
    &:hover {
      color: $text-primary;
      border: 1px solid $placeholder-text-color;
    }
    &:focus,
    &:active {
      color: $text-primary;
      border: 1px solid $placeholder-text-color;
    }
  }
}
