@import "../../../../styles/utils.scss";

@media (max-width: 768px) {
    .thankyou-img{
        height: 150px;
        width: 100%;
    }
    .ant-result-title {
        font-size: 18px !important;
    }
    .ant-result-title {
        font-size: 12px;
    }
}